import { ApolloError } from '@apollo/client';
import { css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';

import { Alert, Box, Button, Modal, Text } from '~/components/blocks';
import { finishDraftAppointmentModalState } from '~/state/reception/atoms';

import { useFinishDraftAppointment } from './use-finish-draft-appointment';

type Props = {
  draftAppointmentId: string;
};

const Guidance = styled(Box)(({ theme }) =>
  css({
    fontSize: theme.fontSizes.s,
    backgroundColor: theme.colors.background.bg,
    marginTop: theme.space.s,
    padding: theme.space.m,
    '& ul, li': {
      listStyleType: 'initial',
      listStylePosition: 'inside',
      paddingLeft: theme.space.xs,
    },
  }),
);
export const FinishDraftAppointmentModal = (props: Props) => {
  const theme = useTheme();
  const [state, setState] = useRecoilState(finishDraftAppointmentModalState);
  const [error, setError] = useState<ApolloError | undefined>(undefined);
  const handleClose = useCallback(() => setState(() => ({ isOpen: false })), [setState]);

  const {
    loading,
    error: finishError,
    handleFinish,
  } = useFinishDraftAppointment({
    draftAppointmentId: props.draftAppointmentId,
  });

  useEffect(() => {
    if (!state.isOpen) {
      setError(undefined);
    }
  }, [state.isOpen]);

  useEffect(() => {
    setError(finishError);
  }, [finishError]);

  return (
    <Modal open={state.isOpen} size="s" onClose={handleClose}>
      <Modal.Header>
        <Text size="l">患者が来局した場合</Text>
      </Modal.Header>
      <Modal.Body>
        {error && <Alert mb={theme.space.l}>エラーが発生したため完了できませんでした</Alert>}
        <Text block>以下の内容をご確認の上、問題なければ完了ボタンを押してください</Text>
        <Guidance>
          <ul>
            <li>アプリ決済での会計はできません。店頭レジにてご対応ください</li>
            <li>
              当日配達<Text size="xs">（Uber Eats）</Text>
              をご希望の場合、キャンセルとなります
            </li>
            <li>患者はオンラインでの服薬指導を受けられなくなります</li>
          </ul>
        </Guidance>
      </Modal.Body>
      <Modal.Footer>
        <Button use="secondary" loading={loading} onClick={handleFinish}>
          完了
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
